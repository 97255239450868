@import "../../utils/scss.variables";
@import "../../utils/scss.mixins";

.services {
  display: flex;
  justify-content: center;

  &__wrapper {
    max-width: 1320px;
    width: 100%;
    padding: 0 75px;
    box-sizing: border-box;
  }

  &__title {
    @include font(bold, normal, 42px, #4F4F4F);
    margin: 65px 0 20px;
  }

  &__services-title {
    @include font(500, normal, 24px, #FFFFFF);
    background-color: #2D9CDB;
    padding: 18px 0 18px 50px;
  }

  &__services-list-wrapper {
    background-color: #EDF5FF;
  }

  &__services-list-item {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;

    &--active {
      background-color: white;
      text-decoration: none;
    }
  }

  &__services-list-item-wrapper {
    display: flex;
    align-items: center;
    height: 60px;
    margin-left: 50px;
  }

  &__service-arrow {
    margin-right: 25px;
    cursor: pointer;
    transition: 0.3s;

    &--active {
      transition: 0.3s;
      margin-right: 25px;
      transform: rotate(90deg);
    }
  }

  &__extended-service {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    padding: 20px 0 20px 87px;
    cursor: pointer;
  }

  &__extended-service-title {
    @include font(500, normal, 14px, #868686);
    line-height: 130%;
    padding: 10px 0 10px 0;
    box-sizing: border-box;
  }

  &__service-title {
    @include font(500, normal, 16px, #4F4F4F);
    cursor: pointer;

    &:hover {
      @include font(500, normal, 16px, #2F80ED);
      text-decoration: underline;
    }
  }
}

@media (max-width: 765px) {
  .services {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &__wrapper {
      max-width: 1320px;
      width: 100%;

      padding: 0 10px;

      box-sizing: border-box;
    }

    &__title {
      @include font(bold, normal, 32px, #4F4F4F);

      margin: 35px 0 20px;
    }

    &__services-title {
      @include font(500, normal, 18px, #FFFFFF);

      background-color: #2D9CDB;
      padding: 18px 0 18px 50px;
    }

    &__services-list-wrapper {
      background-color: #EDF5FF;
    }

    &__services-list-item {
      cursor: default;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    &__services-list-item-wrapper {
      display: flex;
      align-items: center;
      height: 60px;
      margin-left: 20px;
    }

    &__service-arrow {
      margin-right: 20px;
      cursor: pointer;

      &--active {
        margin-right: 20px;
      }
    }

    &__extended-service {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 20px 0 20px 40px;
      cursor: pointer;
      box-sizing: border-box;
    }

    &__extended-service-title {
      @include font(500, normal, 13px, #868686);
      padding: 10px;
      width: 100%;
      max-width: 300px;
    }

    &__service-title {
      @include font(500, normal, 14px, #4F4F4F);
      cursor: pointer;

      &:hover {
        @include font(500, normal, 14px, #2F80ED);
        text-decoration: underline;
      }
    }
  }
}