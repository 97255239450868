@import "../../utils/scss.variables";
@import "../../utils/scss.mixins";
@import "../../utils/scss.extended";

.header {
  display: flex;
  justify-content: center;

  &__wrapper-small-permission {
    display: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 75px;
    height: $header-height;
    font-family: 'Montserrat', sans-serif;
    max-width: 1320px;
    width: 100%;
    box-sizing: border-box;
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__location {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    width: 250px;

    p {
      @include font(normal, normal, 16px, #171717);
      text-decoration: underline;
    }
  }

  &__logo-link {
    width: 75px;
    height: 35px;
  }

  &__link {
    @include font(600, normal, 16px, #171717);
    text-decoration: none;
    margin-left: 20px;

    &:first-child {
      margin: 0;
    }

    &:last-child {
      margin-left: 20px;
      background-color: #2F80ED;
      border-radius: 40px;
      padding: 16px 40px;
      color: white;
      font-size: 12px;
      border: 0;
      @extend .btn-actions;
    }
  }

  &__link-active {
    color: #2F80ED;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      bottom: -40px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #2F80ED;
      border-radius: 5px 5px 0 0;
    }
  }
}

.header-plank__wrapper {
  background-color: #2F80ED;
  display: flex;
  justify-content: center;
}

.header-plank {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  max-width: 1320px;
  padding: 0 75px;
  box-sizing: border-box;

  &__language-wrapper {
    font-family: 'Montserrat', sans-serif;
    @include font(normal, normal, 14px, white);
    cursor: pointer;
    margin: 0 !important;
    display: flex;

    &--small {
      background-color: #2F80EC;
      padding: 10px;
      border-radius: 5px;
    }
  }

  &__language {
    margin: 0 5px;
    transition: 0.3s;

    &--active {
      color: rgba(255, 255, 255, 0.3);
      margin: 0 5px;
      cursor: default;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  &__box {
    max-width: 130px;
  }

  &__text {
    color: white;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    margin-left: 5px;
    min-width: 110px;
    text-decoration: none;
  }

  & div {
    display: flex;
    margin-left: 57px;
  }
}

.copy-icon {
  max-width: 13px;
  cursor: pointer;
}

.icon-wrapper {
  margin: 0;
  width: 20px;
}

.empty {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
}

@media (max-width: 1000px) {
  .header {
    &__location {
      display: none;
    }

    &__wrapper {
      padding: 0 40px;
    }
  }
}

@media (max-width: 768px) {
  .header {

    &__location {
      display: none;
    }

    &__link {
      &:last-child {
        padding: 12px 30px;
      }
    }
  }
}

@media (max-width: 863px) {
  .header {
    &__wrapper {
      display: none
    }

    &__wrapper-small-permission {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__wrapper-small-permission-menu {
      width: 100%;
      height: 63px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      box-sizing: border-box;
    }

    &__wrapper-small-permission-menu-plank {
      display: flex;
      background-color: #2F80ED;
      justify-content: space-around;
      height: 33px;
      flex-wrap: wrap;

      & p {
        color: white;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        margin-left: 10px;
      }

      & div {
        display: flex;
        align-items: center;
      }
    }

    &__open-menu-btn {
      width: 25px;
    }

    &__wrapper-small-permission-menu-links {
      background-color: white;
      position: fixed;
      top: $header-height-small;
      width: 100%;
      height: calc(100% - 96px);
      z-index: 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__link {
      @include font(600, normal, 16px, #171717);
      text-decoration: none;
      margin-left: 0;
      margin-bottom: 30px;
      position: relative;

      &:nth-child(1) {
        margin: 0 0 30px 0;
        animation-delay: -0.1s;
        animation-name: linkOpen1;
        animation-duration: 0.7s;
      }

      &:nth-child(2) {
        animation-delay: -0.2s;
        animation-name: linkOpen2;
        animation-duration: 0.8s;
      }

      &:nth-child(3) {
        animation-delay: -0.3s;
        animation-name: linkOpen3;
        animation-duration: 0.9s;
      }

      &:nth-child(4) {
        animation-delay: -0.4s;
        animation-name: linkOpen4;
        animation-duration: 1s;
      }

      &:nth-child(5) {
        margin-left: 0;
        background-color: #58A5FF;
        border: 0;
        border-radius: 40px;
        padding: 16px 40px;
        color: white;
        font-size: 12px;
        animation-delay: -0.5s;
        animation-name: linkOpen5;
        animation-duration: 1.1s;
      }
    }

    &__link-active {
      &::before {
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
      }
    }
  }

  .header-plank {
    display: none;
  }

  .header-plank__language-wrapper {
    &--small {
      position: relative;
      animation-delay: -0.4s;
      animation-name: linkOpen6;
      animation-duration: 1.2s;
    }
  }
}

@keyframes menuOpen {
  from {
    left: -100%;
  }

  to {
    left: 0;
  }
}

@keyframes linkOpen1 {
  from {
    left: -100%;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes linkOpen2 {
  from {
    bottom: -100%;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes linkOpen3 {
  from {
    right: -100%;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes linkOpen4 {
  from {
    left: -100%;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes linkOpen5 {
  from {
    top: -100%;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes linkOpen6 {
  from {
    right: -100%;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}
