@import "../../utils/scss.variables";
@import "../../utils/scss.mixins";

.footer {
  width: 100%;
  background-color: #2F80ED;
  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    padding: 60px 75px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    max-width: 1320px;
  }

  &__logo {
    width: 179px;
    height: 76px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &__wrapper {
    display: flex;
  }

  &__box {

    &:first-child {
      margin-right: 40px;
    }
  }

  &__sub-box {
    margin-top: 20px;
  }

  &__icon-box {
    display: flex;
    margin-bottom: 10px;
  }

  &__icon {
    margin-right: 5px;
  }

  &__title {
    @include font(500, normal, 18px, white);
  }

  &__text {
    @include font(300, normal, 16px, white);

    &--link {
      text-decoration: underline;
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    &__container {
      flex-direction: column;
      padding: 30px 10px;
    }

    &__wrapper {
      margin-top: 30px;
    }

    &__box {
      &:first-child {
        margin-right: 20px;
      }
    }

    &__title {
      @include font(500, normal, 16px, white);
    }

    &__text {
      @include font(300, normal, 14px, white);
    }
  }
}

@media (max-width: 550px) {
  .footer {
    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__box {
      &:first-child {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}