@import "../../../utils/scss.extended";
@import "../../../utils/scss.mixins";
@import "../../../utils/scss.variables";

.main-doctors {
  display: flex;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 100px 0;

  &__wrapper {
    max-width: 1320px;
    width: 100%;
    padding: 0 75px;
    box-sizing: border-box;
  }

  &__title {
    @include font(bold, normal, 42px, #4F4F4F);
    margin: 65px 0 20px;
  }

  &__subtitle {
    @include font(normal, normal, 16px, #828282);
    margin-top: 20px;
    max-width: 900px;
  }

  &__list {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 375px);
    grid-column-gap: 20px;
    justify-content: center;
  }

  &__list-item {
    box-shadow: 0 4px 14px rgba(194, 194, 194, 0.25);
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 4px 14px rgba(47, 128, 237, 0.15);
    }
  }

  &__doctor-avatar-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 460px;
    background: #EDF5FF;
    border-radius: 8px 8px 0 0;
  }

  &__doctor-avatar {
    height: 100%;
    max-width: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  &__doctor-name {
    @include font(bold, normal, 20px, #4F4F4F);
    padding: 15px 0 0 15px;
  }

  &__doctor-specialization {
    @include font(500, normal, 18px, #EB5757);
    padding: 10px 0 20px 15px;
  }

  &__btn-see-all-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  &__btn-see-all {
    @include font(bold, normal, 14px, #2F80ED);
    @include button(280px, white, 40px, 15px);
    @extend .btn-actions;
    text-decoration: none;
    border: 1px solid #2F80ED;
    text-align: center;
  }
}

@media (max-width: 765px) {
  .main-doctors {
    &__wrapper {
      padding: 0 10px;
    }

    &__title {
      @include font(bold, normal, 32px, #4F4F4F);
      margin: 65px 0 20px;
    }

    &__list {
      //grid-template-columns: repeat(auto-fit, 375px);
      justify-content: center;
    }

    &__list-item {
      box-shadow: 0 4px 14px rgba(194, 194, 194, 0.25);
      border-radius: 0 0 8px 8px;
      margin-bottom: 20px;
    }

    &__doctor-name {
      @include font(bold, normal, 18px, #4F4F4F);
    }

    &__doctor-specialization {
      @include font(500, normal, 16px, #EB5757);
    }
  }
}

@media (max-width: 376px) {
  .main-doctors {
    &__wrapper {
      padding: 0 10px;
    }

    &__title {
      @include font(bold, normal, 32px, #4F4F4F);
      margin: 65px 0 20px;
    }

    &__list {
      grid-template-columns: repeat(auto-fit, 100%);
      justify-content: center;
    }

    &__list-item {
      box-shadow: 0 4px 14px rgba(194, 194, 194, 0.25);
      border-radius: 0 0 8px 8px;
      margin-bottom: 20px;
    }

    &__doctor-avatar {
      //max-height: 361px;
      //max-width: 310px;
    }

    &__doctor-name {
      @include font(bold, normal, 18px, #4F4F4F);
    }

    &__doctor-specialization {
      @include font(500, normal, 16px, #EB5757);
    }
  }
}