@mixin font($weight, $style, $size, $color) {
  font-family: 'Montserrat', sans-serif;
  font-weight: $weight;
  font-style: $style;
  font-size: $size;
  color: $color;
}

@mixin button($width, $b-color, $b-radius, $padding) {
  max-width: $width;
  width: 100%;
  background-color: $b-color;
  border-radius: $b-radius;
  padding: $padding;
  border: 0;
}