@import "../../../utils/scss.mixins";
@import "../../../utils/scss.variables";
@import "../../../utils/scss.extended";

.main-header {
  width: 100%;
  height: 700px;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;

  &__section {
    background-image: url("/assets/main-image.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 75px;
    max-width: 1320px;
    width: 100%;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__background-small-permission {
    display: none;
  }

  &__title {
    @include font(600, normal, 40px, #171717);
    max-width: 570px;
  }

  &__sub-title {
    @include font(normal, normal, 16px, #4F4F4F);
    max-width: 560px;
    margin-top: 20px;
    line-height: 160%;
  }

  &__btn {
    @include font(bold, normal, 14px, white);
    @include button(258px, #2F80ED, 40px, 15px);
    @extend .btn-actions;
    margin-top: 40px;
  }

  &__container-wrapper {
    display: flex;
    justify-content: center;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(194, 194, 194, 0.25);
    border-radius: 15px;
    height: 216px;
    max-width: 1170px;
    width: 100%;
    margin-top: -120px;
  }

  &__box {
    max-width: 382px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-right: 1px solid rgba(88, 165, 255, 0.1);
    height: 140px;
    padding: 0 20px 0 30px;
    width: 100%;

    &:last-child {
      border-right: none;
    }
  }

  &__box-title {
    @include font(600, normal, 20px, #292929);
    margin-bottom: 20px;
  }

  &__box-text {
    @include font(normal, normal, 16px, #4F4F4F);
  }
}

@media (max-width: 768px) {
  .main-header {
    margin: 0 0 250px;
    background-size: contain;
    height: 550px;

    &__section {
      background-image: url("/assets/main-image-small.jpg");
      height: 430px !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 30px 10px 30px 10px;
      background-size: cover;
      position: relative;
    }

    &__background-small-permission {
      display: block;
      position: absolute;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) -2%, rgba(255, 255, 255, 0.78) 100%);
      width: calc(100% - 10px);
      height: 100%;
      z-index: 2;
    }

    &__title {
      @include font(600, normal, 32px, #171717);
      max-width: 550px;
      word-break: break-word;
      position: relative;
      z-index: 3;
    }

    &__sub-title {
      @include font(normal, normal, 14px, #4F4F4F);
      max-width: 560px;
      margin-top: 20px;
      line-height: 160%;
      position: relative;
      z-index: 3;
    }

    &__btn {
      @include font(bold, normal, 14px, white);
      @include button(258px, #58A5FF, 40px, 15px);
      margin-top: 40px;
      position: relative;
      z-index: 3;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #FFFFFF;
      box-shadow: 0;
      border-radius: 15px;
      height: 216px;
      max-width: 100%;
      width: 100%;
      margin-top: 100px;
      padding: 10px;
      box-sizing: border-box;
    }

    &__box {
      max-width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      border-right: none;
      height: 140px;
      padding: 0;
      margin-bottom: 30px;
    }
  }
}