.btn-actions {
  transition: 0.3s;
  &:hover {
    background: #62A5FE;
    color: white;
  }

  &:active {
    background-color: #0054C5;
  }
}

@media (max-width: 765px) {
  .btn-actions {
    transition: 0.3s;

    &:hover {
      background-color: transparent;
      color: white;
    }

    &:active {
      background-color: #0054C5;
    }
  }
}

