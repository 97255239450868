@import "../../utils/scss.variables";
@import "../../utils/scss.mixins";
@import "../../utils/scss.extended";

.service-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__list-wrapper {
    display: flex;
    justify-content: center;
    background-color: #EDF5FF;
    width: 100%;
  }

  &__list-container {
    max-width: 1320px;
    width: 100%;
    padding: 40px 75px;
    box-sizing: border-box;
  }

  &__header {
    max-width: 1320px;
    width: 100%;
    padding: 0 75px;
    box-sizing: border-box;
  }

  &__title {
    @include font(bold, normal, 42px, #4F4F4F);
    margin: 65px 0 20px;
  }

  &__description {
    @include font(normal, normal, 16px, #333333);
    margin-bottom: 60px;
    max-width: 870px;
    line-height: 150%;
  }

  &__sub-title {
    @include font(bold, normal, 32px, #4F4F4F);
    margin-bottom: 30px;
  }

  &__list {
    padding: 30px 0;
    box-sizing: border-box;
  }

  &__item {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }

  &__circle {
    margin-right: 12px;
  }

  &__item-title {
    @include font(500, normal, 18px, #333333);
    line-height: 140%;
  }

  &__doctors-list {
    max-width: 1320px;
    width: 100%;
    padding: 40px 75px;
    box-sizing: border-box;
  }

  &__doctors-list-title {
    @include font(bold, normal, 42px, #4F4F4F);
    margin-bottom: 50px;
  }

  &__doctors-list-item {
    display: flex;
    max-height: 280px;
    margin-bottom: 100px;
  }

  &__doctors-list-item-avatar {
    max-width: 280px;
    border-radius: 10px;
  }

  &__doctors-list-item-wrapper {
    margin-left: 50px;
  }

  &__doctors-list-item-name {
    @include font(500, normal, 24px, #4F4F4F);
    margin-bottom: 20px;
  }

  &__doctors-list-item-sign {
    @include font(500, normal, 16px, #828282);
    margin-bottom: 10px;
  }

  &__doctors-list-item-specialization {
    @include font(500, normal, 18px, #EB5757);
    margin-bottom: 20px;
  }

  &__doctors-list-item-category,
  &__doctors-list-item-experience {
    @include font(normal, normal, 18px, #333333);
    margin-bottom: 20px
  }
}

@media (max-width: 765px) {
  .service-item {
    &__list-container {
      padding: 15px 10px;
    }

    &__header {
      max-width: 1320px;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
    }

    &__title {
      @include font(bold, normal, 32px, #4F4F4F);
      margin: 65px 0 20px;
    }

    &__description {
      @include font(normal, normal, 14px, #333333);
      margin-bottom: 60px;
      max-width: 870px;
      line-height: 150%;
    }

    &__sub-title {
      @include font(bold, normal, 24px, #4F4F4F);
      margin-bottom: 10px;
    }

    &__list {
      padding: 30px 0;
      box-sizing: border-box;
    }

    &__item-title {
      @include font(500, normal, 16px, #333333);
    }

    &__doctors-list {
      padding: 40px 10px;
    }

    &__doctors-list-title {
      margin-bottom: 50px;
    }

    &__doctors-list-item {
      flex-direction: column;
      align-items: center;
      max-height: 100%;
    }

    &__doctors-list-title {
      @include font(bold, normal, 32px, #4F4F4F);
      margin-bottom: 20px;
    }

    &__doctors-list-item-avatar {
      max-width: 320px;
      margin-bottom: 10px;
    }

    &__doctors-list-item-wrapper {
      margin-left: 0;
      text-align: center;
    }
  }
}