@import "../../utils/scss.mixins";
@import "../../utils/scss.variables";

.contact-us-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  background-color: rgba(255,255,255,0.9);
  overflow: auto;

  &__wrapper {
    position: relative;
    width: 100%;
    max-width: 630px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 30px rgba(194, 194, 194, 0.25);
    padding: 40px 0 20px;
    box-sizing: border-box;
    animation-name: contactModal;
    animation-duration: 0.9s;
    animation-direction: normal, reverse;
  }

  &__close-modal {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 4;
  }

  &__title {
    @include font(500, normal, 24px, #333333);
    max-width: 422px;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__label {
    @include font(500, normal, 18px, #333333);
    margin-bottom: 5px;
    padding-left: 30px;
  }

  &__input {
    @include font(normal, normal, 16px, #828282);
    padding: 15px 30px;
    height: 55px;
    border: 1px solid rgba(47, 128, 237, 0.5);
    box-sizing: border-box;
    border-radius: 28px;
    width: 450px;
    margin-bottom: 20px;
    position: relative;

    &:focus {
      border: 1px solid #2F80ED;
    }
  }

  &__btn-submit {
    @include button(152px, white, 40px, 15px);
    @include font(bold, normal, 14px, #2F80ED);
    border: 1px solid #2F80ED;
    box-sizing: border-box;
    margin: 0 auto 20px;
    position: relative;
  }

  &__intermediate-text {
    @include font(normal, normal, 16px, #4F4F4F);
    margin-bottom: 20px;
    position: relative;
  }

  &__text {
    @include font(500, normal, 18px, #333333);
    margin-bottom: 20px;
    position: relative;
  }

  &__phone {
    @include font(500, normal, 24px, #2F80ED);
    position: relative;
  }
}

@media (max-width: 1000px) {
  .contact-us-modal {
    &__wrapper {
      padding: 10px 0;
      margin: 0 10px;
    }

    &__close-modal {
      top: 35px;
      right: 15px;
    }

    &__title {
      @include font(500, normal, 20px, #333333);
      margin-bottom: 20px;
    }

    &__label {
      @include font(500, normal, 14px, #333333);
      padding-left: 20px;
    }

    &__input {
      @include font(normal, normal, 14px, #828282);
      padding: 10px 20px;
      height: 45px;
      width: 300px;
      margin-bottom: 20px;
    }

    &__btn-submit {
      @include button(152px, white, 40px, 10px);
      border: 1px solid #2F80ED;
      margin: 0 auto 10px;
    }

    &__intermediate-text {
      @include font(normal, normal, 14px, #4F4F4F);
      margin-bottom: 10px;
    }

    &__text {
      @include font(500, normal, 16px, #333333);
      margin-bottom: 10px;
    }

    &__phone {
      @include font(500, normal, 18px, #2F80ED);
    }
  }
}

@keyframes contactModal {
  from {
    left: -100%;
  }

  to {
    left: 0;
  }
}
