@import "../../../utils/scss.variables";
@import "../../../utils/scss.mixins";

.contacts-our-contacts {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;

  &__component-wrapper {
    max-width: 1320px;
    width: 100%;
    padding: 0 75px;
    box-sizing: border-box;
  }

  &__sign {
    @include font(bold, normal, 42px, #4F4F4F);
    margin-bottom: 40px;
    margin-top: 65px;
  }

  &__cards {
    display: flex;
    justify-content: space-between;
  }

  &__box {
    display: flex;
    align-items: flex-start;
    box-shadow: 0px 4px 30px rgba(194, 194, 194, 0.25);
    border-radius: 15px;
    max-width: 290px;
    width: 100%;
    height: 150px;
    padding: 32px 38px;
  }

  &__wrapper {
    margin-left: 12px;
  }

  &__title {
    @include font(bold, normal, 24px, #4F4F4F);
    margin-bottom: 25px;
  }

  &__text {
    @include font(normal, normal, 16px, #4F4F4F);
    margin-bottom: 15px;
  }
}

@media (max-width: 1000px) {
  .contacts-our-contacts {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &__component-wrapper {
      max-width: 1320px;
      width: 100%;

      padding: 0 10px;

      box-sizing: border-box;
    }

    &__sign {
      @include font(bold, normal, 36px, #4F4F4F);
      margin-bottom: 40px;
      margin-top: 35px;
    }

    &__cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__box {
      display: flex;
      align-items: flex-start;
      border-radius: 15px;
      box-shadow: none;
      max-width: 290px;
      width: 100%;
      height: 150px;
      padding: 0;
    }

    &__wrapper {
      margin-left: 30px;
    }

    &__title {
      @include font(bold, normal, 16px, #4F4F4F);
      margin-bottom: 25px;
      text-align: center;
    }

    &__text {
      @include font(normal, normal, 14px, #4F4F4F);
      margin-bottom: 15px;
      text-align: center;
    }
  }
}

@media (max-width: 600px) {
  .contacts-our-contacts {
    &__cards {
      flex-direction: column;
    }
  }
}