@import "../../../utils/scss.variables";
@import "../../../utils/scss.mixins";
@import "../../../utils/scss.extended";

.main-services {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__wrapper {
    max-width: 1320px;
    width: 100%;
    padding: 0 75px;
    box-sizing: border-box;
  }

  &__sign {
    @include font(bold, normal, 42px, #4F4F4F);
  }

  &__sub-title {
    @include font(normal, normal, 16px, #828282);
    margin-top: 20px;
    max-width: 900px;
    line-height: 130%;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 50px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 15px 15px 40px;
    max-width: 388px;
    width: 100%;
    height: 152px;
    box-sizing: border-box;
    border: 1px solid #F2F2F2;
    box-shadow: 0 4px 30px rgba(194, 194, 194, 0.25);
    border-radius: 15px;
    transition: 0.3s;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      border: 1px solid #2F80ED;
      box-shadow: 0 4px 30px rgba(47, 128, 237, 0.06);
    }
  }

  &__icon {
    width: 100%;
    max-width: 57px;
    margin-right: 15px;
  }

  &__name {
    @include font(500, normal, 16px, #4F4F4F);
    max-width: 200px;
    word-break: break-word;
    text-transform: uppercase;
    line-height: 150%;
  }

  &__btn-see-all-wrapper {
    display: flex;
    justify-content: center;
  }

  &__btn-see-all {
    @include font(600, normal, 14px, #2F80ED);
    @include button(280px, white, 40px, 15px);
    @extend .btn-actions;
    margin: 50px 0;
    border: 1px solid #2F80ED;
    text-decoration: none;
    text-align: center;
  }
}

@media (max-width: 1000px) {
  .main-services {
    &__wrapper {
      max-width: 1320px;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      margin-top: 50px;
    }

    &__item {
      padding: 10px;
    }

    &__icon {
      max-width: 37px;
      margin-right: 10px;
    }

    &__name {
      @include font(500, normal, 14px, #4F4F4F);
    }
  }
}

@media (max-width: 765px) {
  .main-services {
    &__wrapper {
      max-width: 1320px;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
    }

    &__sign {
      @include font(bold, normal, 30px, #4F4F4F);
    }

    &__sub-title {
      @include font(normal, normal, 14px, #828282);
      margin-top: 10px;
      max-width: 900px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      margin-top: 50px;
    }

    &__item {
      height: 135px;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-around;
    }

    &__icon {
      max-width: 37px;
      margin-left: auto;
    }

    &__name {
      @include font(500, normal, 14px, #4F4F4F);
      margin-right: auto;
    }
  }
}