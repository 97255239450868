@import "../../utils/scss.variables";
@import "../../utils/scss.mixins";

.links {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  width: 100%;
  justify-content: center;

  &__wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
  }

  &__link-text {
    @include font(normal, normal, 14px, #828282);
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
      @include font(normal, normal, 14px, #4F4F4F);
    }
  }
}