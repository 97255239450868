@import "../../utils/scss.mixins";
@import "../../utils/scss.variables";
@import "../../utils/scss.extended";

.success-modal {
  background-color: rgba(255,255,255,0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    max-width: 630px;
    padding: 255px 80px 40px 70px;
    box-sizing: border-box;
    animation-name: successModal;
    animation-duration: 0.9s;
  }

  &__close-modal {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 4;
  }

  &__title {
    @include font(500, normal, 36px, #333333);
    margin-bottom: 39px;
    position: relative;
  }

  &__sub-title {
    @include font(500, normal, 24px, #828282);
    text-align: center;
    margin-bottom: 40px;
    position: relative;
  }

  &__btn {
    @include button(200px, white, 40px, 15px);
    @include font(bold, normal, 14px, #2F80ED);
    @extend .btn-actions;
    border: 1px solid #2F80ED;
    box-sizing: border-box;
    margin-bottom: 70px;
    position: relative;
  }

  &__intermediate-text {
    @include font(normal, normal, 16px, #4F4F4F);
    margin-bottom: 20px;
    position: relative;
  }

  &__text {
    @include font(500, normal, 18px, #333333);
    margin-bottom: 20px;
    position: relative;
  }

  &__phone {
    @include font(500, normal, 24px, #2F80ED);
    position: relative;
  }
}

@media (max-width: 1000px) {
  .success-modal {
    &__wrapper {
      padding: 85px 0;
      margin: 0 10px;
    }

    &__close-modal {
      top: 35px;
      right: 15px;
    }

    &__title {
      @include font(500, normal, 20px, #333333);
      margin-bottom: 40px;
    }

    &__sub-title {
      @include font(500, normal, 16px, #828282);
      margin-bottom: 40px;
    }

    &__btn {
      @include button(200px, white, 40px, 10px);
      border: 1px solid #2F80ED;
      margin: 0 auto 10px;
    }

    &__intermediate-text {
      @include font(normal, normal, 14px, #4F4F4F);
      margin-bottom: 10px;
    }

    &__text {
      @include font(500, normal, 16px, #333333);
      margin-bottom: 10px;
    }

    &__phone {
      @include font(500, normal, 18px, #2F80ED);
    }
  }
}

@keyframes successModal {
  from {
    left: -100%;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}
