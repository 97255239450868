@import "../../../utils/scss.variables";
@import "../../../utils/scss.mixins";
@import "../../../utils/scss.extended";

.treatment-process {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;

  &__title {
    @include font(bold, normal, 42px, #4F4F4F);
    margin: 65px 0 40px;
  }

  &__container {
    display: flex;
  }

  &__box {
    max-width: 370px;
    width: 100%;
    margin-right: 20px;
    display: flex;
    align-items: flex-start;
  }

  &__number {
    @include font(bold, normal, 20px, #2F80ED);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #2F80ED;
    height: 50px;
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    margin-right: 10px;
  }

  &__sub-title {
    @include font(bold, normal, 20px, #4F4F4F);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
  }

  &__text {
    @include font(normal, normal, 16px, #828282);
    line-height: 150%;
  }
}

@media (max-width: 765px) {
  .treatment-process {

    &__title {
      @include font(bold, normal, 32px, #4F4F4F);
      margin: 35px 0 20px;
    }

    &__container {
      flex-direction: column;
    }

    &__box {
      max-width: 100%;
      margin-bottom: 20px;
    }

    &__number {
      @include font(bold, normal, 18px, #2F80ED);
    }

    &__sub-title {
      @include font(bold, normal, 18px, #4F4F4F);
      margin-bottom: 0;
    }

    &__text {
      @include font(normal, normal, 14px, #828282);
    }
  }
}