@import "../../utils/scss.variables";
@import "../../utils/scss.mixins";
@import "../../utils/scss.extended";

.doctors {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  &__wrapper {
    max-width: 1320px;
    width: 100%;
    padding: 0 75px;
    box-sizing: border-box;
  }

  &__title {
    @include font(bold, normal, 42px, #4F4F4F);
    margin: 65px 0 20px;
  }

  &__selectors {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0;
  }

  &__selector {
    @include font(500, normal, 16px, #2F80ED);
    text-align: center;
    background-color: #EDF5FF;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.3s;
    margin: 0 10px 15px 0;
    border: 1px solid transparent;

    &--active {
      border: 1px solid #2F80ED;
      background-color: white;
    }

    &:hover {
      background-color: #BEDCFF;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 370px));
    grid-gap: 30px;
    justify-content: center;
  }

  &__list-item {
    box-shadow: 0 4px 14px rgba(194, 194, 194, 0.25);
    border-radius: 0 0 8px 8px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 14px rgba(47, 128, 237, 0.15);
    }
  }

  &__doctor-avatar-wrapper {
    display: flex;
    align-items: flex-end;
    border-radius: 8px 8px 0 0;
  }

  &__doctor-avatar {
    height: 450px;
    background: #EDF5FF;
    max-width: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  &__doctor-name {
    @include font(bold, normal, 20px, #4F4F4F);
    padding: 15px 15px 0 15px;
    box-sizing: border-box;
  }

  &__doctor-specialization {
    @include font(500, normal, 18px, #EB5757);
    padding: 10px 0 0 15px;
    box-sizing: border-box;
  }

  &__doctor-category {
    @include font(500, normal, 18px, #4F4F4F);
    padding: 10px 0 0 15px;
    box-sizing: border-box;
  }

  &__doctor-experience {
    @include font(500, normal, 18px, #828282);
    padding: 10px 0 20px 15px;
    box-sizing: border-box;
  }
}

@media (max-width: 765px) {
  .doctors {
    margin-top: 10px;

    &__wrapper {
      padding: 0 10px;
    }

    &__title {
      @include font(bold, normal, 32px, #4F4F4F);

      margin: 35px 0 20px;
    }

    &__selector {
      @include font(500, normal, 14px, #2F80ED);

      &:hover {
        border: 1px solid #2F80ED;
        background-color: white;
      }
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
      grid-gap: 20px;
      justify-content: center;
    }

    &__list-item {
      box-shadow: 0px 4px 14px rgba(194, 194, 194, 0.25);
      border-radius: 0px 0px 8px 8px;
      transition: 0.3s;
      max-width: 300px;

      &:hover {
        box-shadow: 0 4px 14px rgba(47, 128, 237, 0.15);
      }
    }

    &__doctor-avatar {
      height: 400px;
      background: #EDF5FF;
      max-width: 100%;
      width: 100%;
      border-radius: 8px 8px 0 0;
    }

    &__doctor-name {
      @include font(bold, normal, 18px, #4F4F4F);
      padding: 15px 15px 0 15px;
      box-sizing: border-box;
    }

    &__doctor-specialization {
      @include font(500, normal, 16px, #EB5757);
      padding: 10px 0 0 15px;
      box-sizing: border-box;
    }

    &__doctor-category {
      @include font(500, normal, 16px, #4F4F4F);
      padding: 10px 0 0 15px;
      box-sizing: border-box;
    }

    &__doctor-experience {
      @include font(500, normal, 16px, #828282);
      padding: 10px 0 20px 15px;
      box-sizing: border-box;
    }
  }
}

@media (max-width: 376px) {
  .doctors {
    margin-top: 10px;

    &__wrapper {
      padding: 0 10px;
    }

    &__title {
      @include font(bold, normal, 32px, #4F4F4F);

      margin: 35px 0 20px;
    }

    &__selector {
      @include font(500, normal, 14px, #2F80ED);

      &:hover {
        border: 1px solid #2F80ED;
        background-color: white;
      }
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 100%));
      grid-gap: 20px;
      justify-content: center;
    }

    &__list-item {
      box-shadow: 0 4px 14px rgba(194, 194, 194, 0.25);
      border-radius: 0 0 8px 8px;
      transition: 0.3s;
      max-width: 100%;

      &:hover {
        box-shadow: 0 4px 14px rgba(47, 128, 237, 0.15);
      }
    }

    &__doctor-name {
      @include font(bold, normal, 18px, #4F4F4F);
      padding: 15px 15px 0 15px;
      box-sizing: border-box;
    }

    &__doctor-specialization {
      @include font(500, normal, 16px, #EB5757);
      padding: 10px 0 0 15px;
      box-sizing: border-box;
    }

    &__doctor-category {
      @include font(500, normal, 16px, #4F4F4F);
      padding: 10px 0 0 15px;
      box-sizing: border-box;
    }

    &__doctor-experience {
      @include font(500, normal, 16px, #828282);
      padding: 10px 0 20px 15px;
      box-sizing: border-box;
    }
  }
}