@import "../../../utils/scss.variables";
@import "../../../utils/scss.mixins";

.contacts-about-us {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  margin: 90px 0 135px;

  &__component-wrapper {
    width: 100%;
    max-width: 1320px;
    padding: 0 75px;
    box-sizing: border-box;
  }

  &__title {
    @include font(bold, normal, 48px, #4F4F4F);
    margin-bottom: 20px;
  }

  &__text {
    @include font(normal, normal, 18px, #4F4F4F);
    margin-bottom: 50px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__img {
    max-width: 540px;
    max-height: 361px;
    width: 100%;
    height: 100%;
  }

  &__about-us-text {
    @include font(normal, normal, 16px, #333333);
    max-width: 575px;
    width: 100%;
    line-height: 150%;
    margin-left: 10px;
  }
}

@media (max-width: 1000px) {
  .contacts-about-us {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    margin: 0 0 55px;

    &__component-wrapper {
      width: 100%;
      max-width: 1320px;
      padding: 0 10px;
      box-sizing: border-box;
    }

    &__wrapper {
      flex-direction: column;
    }

    &__img {
      max-width: 100%;
      max-height: 561px;
    }

    &__title {
      @include font(bold, normal, 32px, #4F4F4F);
      margin-bottom: 20px;
    }

    &__about-us-text {
      margin-left: 0;
      margin-top: 30px;
    }
  }
}