@import "../../utils/scss.variables";
@import "../../utils/scss.mixins";
@import "../../utils/scss.extended";

.contact-us-component {
  display: flex;
  justify-content: center;
  background-color: #EDF5FF;
  width: 100%;

  &__wrapper {
    max-width: 1320px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    padding: 70px 75px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }

  &__title {
    @include font(600, normal, 42px, #4F4F4F);
    margin-bottom: 25px;
  }

  &__sub-title {
    @include font(400, normal, 18px, #828282);
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 576px;
    margin-bottom: 20px;
  }

  &__form-label {
    @include font(500, normal, 18px, #333333);
    margin: 0 0 5px 20px;
  }

  &__form-input {
    @include font(normal, normal, 16px, #333333);
    border: 1px solid rgba(47, 128, 237, 0.5);
    box-sizing: border-box;
    border-radius: 28px;
    padding: 15px 30px;
    font-family: 'Montserrat', sans-serif;

    &:focus {
      border: 1px solid #2F80ED;
    }
  }

  &__form-submit {
    @include font(bold, normal, 14px, #2F80ED);
    @include button(152px, #EDF5FF, 40px, 15px);
    @extend .btn-actions;
    border: 1px solid #2F80ED;
  }
}

@media (max-width: 1000px) {
  .contact-us-component {
    display: flex;
    justify-content: center;

    &__wrapper {
      padding: 30px 10px;
      flex-direction: column;
    }

    &__title {
      @include font(600, normal, 32px, #4F4F4F);
      margin-bottom: 15px;
      text-align: center;
    }

    &__sub-title {
      @include font(400, normal, 18px, #828282);
      margin-bottom: 30px;
      text-align: center;
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__form-wrapper {
      display: flex;
      flex-direction: column;
      min-width: 276px;
      max-width: 500px;
      width: 100%;
      margin-bottom: 20px;
    }

    &__form-label {
      @include font(500, normal, 18px, #333333);
      margin: 0 0 5px 20px;
    }

    &__form-input {
      border: 1px solid rgba(47, 128, 237, 0.5);
      box-sizing: border-box;
      border-radius: 28px;
      padding: 15px 30px;
    }

    &__form-submit {
      @include font(bold, normal, 14px, #2F80ED);
      @include button(152px, #EDF5FF, 40px, 15px);
      margin-left: 0;
      border: 1px solid #2F80ED;
    }
  }
}