@import "../../../utils/scss.mixins";
@import "../../../utils/scss.variables";

.main-reviews {
  margin: 40px auto 60px;
  max-width: 1320px;

  &__sign {
    @include font(bold, normal, 42px, #4F4F4F);
    padding: 0 75px;
    margin-bottom: 57px;
  }

  &__arrow-left {
    transform: rotate(180deg);
  }

  &__card {
    max-width: 380px;
    width: 100%;
    height: 300px;
    box-shadow: 0 4px 14px rgba(194, 194, 194, 0.25);
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  &__card-name {
    @include font(bold, normal, 20px, #4F4F4F);
    margin-bottom: 12px;
  }

  &__card-date {
    @include font(normal, normal, 16px, #6B6B6B);
    margin-bottom: 20px;
  }

  &__card-text {
    @include font(normal, normal, 16px, #4F4F4F);
    margin-bottom: 15px;
    line-height: 150%;
  }

  &__card-stars-wrapper {
    margin: auto 0 0 auto;
  }

  .BrainhubCarousel__dot {
    padding: 0;
    border-radius: 50%;
    margin: 60px 10px 0 0;
  }

  .BrainhubCarousel__dot:before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #F2F2F2;
  }

  .BrainhubCarousel__dot--selected {
    border-radius: 50%;
  }

  .BrainhubCarousel__dot--selected:before {
    background-color: #2F80ED;
  }
}

@media (max-width: 765px) {
  .main-reviews {
    &__arrow {
      display: none;
    }

    &__sign {
      @include font(bold, normal, 32px, #4F4F4F);
      padding: 0 10px;
      margin-bottom: 37px;
    }

    &__card {
      padding: 10px;
      max-width: 320px;
    }

    .BrainhubCarousel__dot {
      padding: 0;
      border-radius: 50%;
      margin: 30px 10px 0 0;
    }

    li.BrainhubCarouselItem {
      width: 320px;
    }

    .BrainhubCarousel__trackContainer {
      margin: 0 auto;
      width: 320px;
    }

    li.BrainhubCarouselItem.BrainhubCarouselItem--active {
      width: 320px;
    }
  }
}